import {
    Container,
    Grid,
    Hidden,
    Icon,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as TripadvisorIcon } from 'assets/icons/tripadvisor.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import Plate001 from 'assets/images/tartare1.webp';
import Plate002 from 'assets/images/ravioli1.webp';
import Plate003 from 'assets/images/BigoliCacioPepe1.webp';
import Plate004 from 'assets/images/ribs1.webp';
import Plate005 from 'assets/images/secretoIberico1.webp';
import { analytics } from 'config/firebase';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { BigButton, DecoIconButton } from 'styles/buttons';

import { CoverWrapper, Heading, Orbit, Plate, SocialBox, System } from './styles';

export const Cover = () => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <CoverWrapper>
            <Container>
                <Grid
                    container
                    alignItems="center"
                    {...(small ? { direction: 'column-reverse' } : {})}
                >
                    <Grid item xs md={6} lg={5}>
                        <Heading>
                            <Typography variant="h1">Indecisi, ma non sulla qualità.</Typography>
                            <RouterLink to="/menu">
                                <BigButton
                                    variant="contained"
                                    onClick={() => {
                                        analytics.logEvent('open-menu', {
                                            origin: 'cover',
                                        });
                                    }}
                                >
                                    Guarda il menu
                                </BigButton>
                            </RouterLink>
                        </Heading>
                    </Grid>
                    <Hidden lgDown>
                        <Grid item xs={12} lg={1} />
                    </Hidden>
                    <Grid item xs md={6} lg={6}>
                        <System>
                            <Orbit initialDegree={0} showTrail />
                            <Orbit initialDegree={0} tier={2} showTrail />
                            <Orbit initialDegree={0}>
                                <Plate src={Plate001} />
                            </Orbit>
                            <Orbit initialDegree={120}>
                                <Plate src={Plate004} />
                            </Orbit>
                            <Orbit initialDegree={270}>
                                <Plate src={Plate005} />
                            </Orbit>
                            <Orbit initialDegree={45} tier={2}>
                                <Plate src={Plate001} />
                            </Orbit>
                            <Orbit initialDegree={200} tier={2}>
                                <Plate src={Plate003} />
                            </Orbit>
                            <Orbit initialDegree={300} tier={2}>
                                <Plate src={Plate002} />
                            </Orbit>
                            <Plate src={Plate001} rotate />
                        </System>
                    </Grid>
                </Grid>
                <SocialBox>
                    <a
                        href="https://kutt.it/teledeciso"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <DecoIconButton
                            onClick={() => {
                                analytics.logEvent('social-seek', {
                                    social: 'telegram',
                                    origin: 'website-cover',
                                });
                            }}
                        >
                            <Icon>
                                <TelegramIcon />
                            </Icon>
                        </DecoIconButton>
                    </a>
                    <a
                        href="https://kutt.it/indecisogram"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <DecoIconButton
                            onClick={() => {
                                analytics.logEvent('social-seek', {
                                    social: 'instagram',
                                    origin: 'website-cover',
                                });
                            }}
                        >
                            <Icon>
                                <InstagramIcon />
                            </Icon>
                        </DecoIconButton>
                    </a>
                    <a
                        href="https://kutt.it/indecisbook"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <DecoIconButton
                            onClick={() => {
                                analytics.logEvent('social-seek', {
                                    social: 'facebook',
                                    origin: 'website-cover',
                                });
                            }}
                        >
                            <Icon>
                                <FacebookIcon />
                            </Icon>
                        </DecoIconButton>
                    </a>
                    <a
                        href="https://kutt.it/tripindecisor"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <DecoIconButton
                            onClick={() => {
                                analytics.logEvent('social-seek', {
                                    social: 'tripadvisor',
                                    origin: 'website-cover',
                                });
                            }}
                        >
                            <Icon>
                                <TripadvisorIcon />
                            </Icon>
                        </DecoIconButton>
                    </a>
                </SocialBox>
            </Container>
        </CoverWrapper>
    );
};
