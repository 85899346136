import ConditionalLink from 'components/ConditionalLink';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { absoluteBlock } from '../mixins/pseudo';

export const Link = styled(ConditionalLink)`
    position: relative;
    text-decoration: none;
    color: ${props => transparentize(0.6, props.theme.palette.text.primary)};
    transition: 0.2s all ease-in-out;
    z-index: 1;
    font-weight: 700;
    height: 40px;
    line-height: 40px;

    &:hover {
        color: ${props => props.theme.palette.text.primary};

        &::before {
            height: 4px;
        }
    }

    &::before {
        ${absoluteBlock};
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 0px;
        background: ${props => props.theme.palette.primary.main};
        z-index: -1;
        transition: 0.2s all ease-in-out;
    }
`;
