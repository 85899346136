import React, { useState, useEffect } from 'react';
import { PageWrapper } from 'components/PageWrapper';
import { Container, CircularProgress, Box } from '@material-ui/core';
import axios from 'axios';
import { LegalPaper } from './styles';

export const Privacy = () => {
    const [privacyContent, setPrivacyContent] = useState('');
    const [networkError, setNetworkError] = useState<Error | null>(null);

    useEffect(() => {
        const getPolicy = async () => {
            const policyResponse = await axios
                .get('https://www.iubenda.com/api/privacy-policy/73740718/no-markup', {
                    responseType: 'text',
                })
                .catch(setNetworkError);
            console.log('fuck');
            if (networkError || !policyResponse) return;

            setPrivacyContent(policyResponse.data.content);
        };

        getPolicy();
    }, []);

    return (
        <PageWrapper>
            {privacyContent ? (
                <Container>
                    <LegalPaper dangerouslySetInnerHTML={{ __html: privacyContent }} />
                </Container>
            ) : (
                <Box height={256} display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
        </PageWrapper>
    );
};
