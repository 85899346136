import { Avatar } from '@material-ui/core';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { BigButton } from 'styles/buttons';
import { fullBlock } from 'styles/mixins/pseudo';
import { RatioBox } from 'styles/ratioBox';

export const AboutWrapper = styled.div`
    padding-top: 64px;

    ${BigButton} {
        margin-top: 48px;
    }

    ${RatioBox} {
        position: relative;
        z-index: -1;

        ${({ theme }) => theme.breakpoints.down('sm')} {
            margin-bottom: 32px;
        }
    }
`;

export const AboutImage = styled(Avatar)`
    position: relative;
    height: 100%;
    width: 100%;
    box-shadow: 0 0 0 80px #131313, 0 0 0 84px ${transparentize(0.8, '#979797')};
    background: #333;

    &::after {
        ${fullBlock};
        transform: scale(1.2);
        border-radius: 50%;
        border: 4px solid ${({ theme }) => theme.palette.primary.main};
    }
`;
