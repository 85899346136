import React from 'react';
import { Wrapper } from './styles';

interface Props {
    children: JSX.Element | JSX.Element[];
}

export const PageWrapper: React.FC<Props> = ({ children }: Props) => {
    return <Wrapper>{children}</Wrapper>;
};
