import { Button, Container } from '@material-ui/core';
import { css } from '@material-ui/system';
import { transparentize } from 'polished';
import styled, { keyframes } from 'styled-components';
import { flexColumn, flexSlide } from 'styles/mixins/flex';

export const CoverWrapper = styled.div`
    ${flexSlide};
    /* overflow: hidden; */

    a {
        text-decoration: none;
    }

    ${props => props.theme.breakpoints.down('sm')} {
        & .MuiContainer-root:first-of-type,
        & .MuiGrid-container {
            height: 100%;
        }
    }
`;

export const Heading = styled.hgroup`
    position: relative;
    z-index: 10;

    h1 {
        text-shadow: 0 0 8px #131313;

        ${props => props.theme.breakpoints.down('sm')} {
            max-width: 90%;
        }
    }

    ${props => props.theme.breakpoints.down('sm')} {
        ${flexColumn};
        height: 100%;
        align-items: flex-start;
        justify-content: flex-end;
        padding-bottom: 24px;
    }
`;

export const System = styled.div`
    position: relative;
    transform: translateX(40%);

    ${props => props.theme.breakpoints.down('md')} {
        max-height: 50vh;
        max-width: 50vh;
    }

    ${props => props.theme.breakpoints.down('sm')} {
        transform: translate(40%, -4vh);

        @media (min-height: 750px) {
            transform: translate(40%, 12vh);
        }
    }
`;

const getRotation = (initialDegree: number) => keyframes`
  from {
    transform: rotate(${initialDegree}deg);
  }

  to {
    transform: rotate(${initialDegree + 360}deg);
  }
`;

export const Plate = styled.img<{ rotate?: boolean }>`
    display: block;
    width: 100%;
    animation: ${props => (props.rotate ? getRotation(0) : null)} 240s linear infinite;
    pointer-events: ${props => (props.rotate ? 'none' : 'all')};
`;

export const Orbit = styled.div<{ initialDegree: number; tier?: number; showTrail?: boolean }>`
    position: absolute;
    top: -${({ tier }) => (tier || 1) * (((tier || 1) + 1) * 4) + 10}%;
    left: -${({ tier }) => (tier || 1) * (((tier || 1) + 1) * 4) + 10}%;
    height: ${({ tier }) => (tier || 1) * (((tier || 1) + 1) * 8) + 120}%;
    width: ${({ tier }) => (tier || 1) * (((tier || 1) + 1) * 8) + 120}%;
    animation: ${({ initialDegree }) => getRotation(initialDegree)}
        ${({ tier }) => 60 + (tier || 1) * 15}s linear infinite;
    border: ${props =>
        props.showTrail
            ? `4px solid ${transparentize(
                  0.5 + 0.2 * (props.tier || 1),
                  props.theme.palette.primary.main,
              )}`
            : ''};
    border-radius: 50%;
    pointer-events: none;

    ${props => props.theme.breakpoints.down('sm')} {
        top: -${({ tier }) => (tier || 1) * (((tier || 1) + 1) * 5) + 5}%;
        left: -${({ tier }) => (tier || 1) * (((tier || 1) + 1) * 5) + 5}%;
        height: ${({ tier }) => (tier || 1) * (((tier || 1) + 1) * 10) + 110}%;
        width: ${({ tier }) => (tier || 1) * (((tier || 1) + 1) * 10) + 110}%;
    }

    ${Plate} {
        position: absolute;
        top: 0;
        left: 50%;
        height: 80px;
        width: 80px;
        transform: translate(-50%, -50%);
        transform-origin: 50% 50%;
        box-shadow: 0 0 0 0px ${props => props.theme.palette.background.default},
            0 0 0 2px ${props => props.theme.palette.primary.main};
        border-radius: 50%;
        border: none;
        padding: 8px;
        z-index: 10;
        transition: 0.2s;
        cursor: pointer;
        pointer-events: all;

        ${props => props.theme.breakpoints.down('md')} {
            height: 64px;
            width: 64px;
            padding: 4px;
        }

        ${props => props.theme.breakpoints.down('sm')} {
            height: 56px;
            width: 56px;
            padding: 4px;
        }

        &:hover {
            box-shadow: 0 0 0 0px ${props => props.theme.palette.background.default},
                0 0 0 4px ${props => props.theme.palette.primary.main};
        }
    }
`;

export const SocialBox = styled(Container)`
    position: absolute;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%);

    ${props => props.theme.breakpoints.down('sm')} {
        display: none;
    }

    & .MuiIconButton-root {
        margin: 8px;
        margin-right: 20px;
    }
`;
