import { Button, IconButton } from '@material-ui/core';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { darken } from '@material-ui/core/styles';
import React from 'react';
import styled, { css } from 'styled-components';

type SquircleButtonProps = {
    solid?: boolean;
};

const sizeMultiplier = {
    small: 0.75,
    medium: 1,
};

export const SquircleIconButton = styled(
    ({ solid, ...rest }: IconButtonProps & SquircleButtonProps) => <IconButton {...rest} />,
).attrs({
    disableRipple: true,
})<SquircleButtonProps>`
    padding: 0;
    border-radius: 0;
    border-radius: 36%;
    transition: all 0.2s ease-out;
    height: ${({ size }) => 56 * sizeMultiplier[size || 'medium']}px;
    width: ${({ size }) => 56 * sizeMultiplier[size || 'medium']}px;
    background: ${({ solid }) => (solid ? '#d3d3d3' : 'transparent')};
    border: ${({ solid }) => (solid ? 'none' : '4px solid #d3d3d3')};

    span {
        width: 100%;
        height: 100%;
    }

    svg {
        height: 50%;
        width: 50%;
        fill: #333;
        transition: all 0.2s ease-out;
    }

    &:hover {
        background: ${({ solid }) => (solid ? darken('#d3d3d3', 0.2) : 'transparent')};
        border-color: ${({ solid }) => (solid ? 'none' : darken('#d3d3d3', 0.2))};
    }

    &:focus {
        background: ${({ solid }) => (solid ? darken('#d3d3d3', 0.4) : 'transparent')};
        border-color: ${({ solid }) => (solid ? 'none' : darken('#d3d3d3', 0.4))};
        border-width: 6px;
    }

    &:active {
        background: ${({ solid }) => (solid ? '#111' : 'transparent')};
        border-color: ${({ solid }) => (solid ? 'none' : '#111')};
        ${({ solid }) =>
            solid &&
            css`
                svg {
                    fill: #fff;
                }
            `}
    }
`;

export const BigButton = styled(Button).attrs({
    variant: 'contained',
    color: 'primary',
    size: 'large',
})<{ alterned?: boolean }>`
    height: 64px;
    min-width: 224px;

    ${({ alterned, theme }) =>
        alterned
            ? css`
                  background-color: rgba(255, 255, 255, 0.05);
                  box-shadow: none;
                  color: ${theme.palette.primary.main};

                  &:hover {
                      background-color: rgba(255, 255, 255, 0.1);
                  }
              `
            : null};

    ${props => props.theme.breakpoints.down('sm')} {
        height: 56px;
        min-width: 224px;
    }
`;

export const DecoIconButton = styled(IconButton).attrs({
    color: 'primary',
})`
    margin: 8px;
    box-shadow: 0 0 0 1px ${props => props.theme.palette.primary.main},
        0 0 0 3px ${props => props.theme.palette.background.default},
        0 0 0 4px ${props => props.theme.palette.primary.main};
    transition: 0.2s;

    &:hover {
        box-shadow: 0 0 0 2px ${props => props.theme.palette.primary.main},
            0 0 0 5px ${props => props.theme.palette.background.default},
            0 0 0 6px ${props => props.theme.palette.primary.main};
    }

    svg {
        fill: ${props => props.theme.palette.primary.main};
    }
`;
