import { lighten } from 'polished';
import styled from 'styled-components';
import { BigButton, DecoIconButton } from 'styles/buttons';

export const Wrapper = styled.div`
    overflow: hidden;
`;

export const CardToAction = styled.div`
    padding: 80px 64px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 4px;

    ${props => props.theme.breakpoints.down('xs')} {
        padding: 48px 24px;
    }

    h2 {
        font-size: 2rem;
        line-height: 2.4rem;
        margin-bottom: 0;
        color: ${({ theme }) => theme.palette.background.default};
    }

    ${BigButton} {
        box-shadow: none;
        background-color: ${({ theme }) => theme.palette.background.default};
        color: ${({ theme }) => theme.palette.text.primary};
        float: right;

        ${props => props.theme.breakpoints.down('xs')} {
            margin-top: 48px;
            float: none;
        }

        &:hover {
            background-color: ${({ theme }) => lighten(0.05, theme.palette.background.default)};
        }
    }
`;

export const Foot = styled.footer`
    margin-top: 128px;
    margin-bottom: 64px;

    p:first-child {
        opacity: 1;
        margin-bottom: 12px;
    }

    strong {
        font-weight: 600;
        font-family: Poppins;
        text-transform: uppercase;
    }

    ${DecoIconButton} {
        margin-right: 16px;
    }
`;
