const groupBy = <T extends Record<string, any>>(
    arr: T[],
    criteria: string,
    initializer?: Record<string, []>,
): Record<string, T[]> => {
    return arr.reduce((obj: Record<string, T[]>, item) => {
        const key = item[criteria];
        if (typeof key !== 'string') throw new Error('grouping criteria must be a string');

        const newObj = { ...obj, [key]: [...(obj[key] || []), item] };
        return newObj;
    }, initializer || {});
};

export default groupBy;
