import { Box, Container, Grid, IconButton, Typography } from '@material-ui/core';
import DirectionsIcon from '@material-ui/icons/DirectionsOutlined';
import React from 'react';
import ReactMapboxGl, { Feature, Marker } from 'react-mapbox-gl';
import { DefaultSpacing } from 'styles/Layout';

import { MapCard, MapContainer, MarkerBullet, MarkerPopup, Timetable } from './styles';

const Map = ReactMapboxGl({
    accessToken:
        'pk.eyJ1IjoiZWwxZmxlbSIsImEiOiJjazMwdTZydm8wMDR6M2dwOXJxOHJxdnJ2In0.L7pO7TjPwY-KEGpcbbHHuA',
    scrollZoom: false,
});

export const Position = () => {
    return (
        <DefaultSpacing>
            <MapContainer>
                <Container>
                    <Typography variant="h2" align="center">
                        Dove trovarci
                    </Typography>
                    <MapCard>
                        <Map
                            // eslint-disable-next-line react/style-prop-object
                            style="mapbox://styles/el1flem/ck2wk4qpl0r0d1cn71jj3frde"
                            center={[11.907623, 45.402175]}
                            zoom={[13]}
                            containerStyle={{
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <Marker coordinates={[11.907623, 45.402175]} anchor="bottom">
                                <MarkerBullet />
                            </Marker>
                            <MarkerPopup
                                coordinates={[11.907623, 45.402175]}
                                offset={{
                                    bottom: [0, -24],
                                }}
                            >
                                <Typography variant="h3">L'INDECISO</Typography>
                                <Typography>
                                    <small>Ottieni indicazioni</small>
                                </Typography>
                                <div>
                                    <IconButton
                                        size="small"
                                        component="a"
                                        target="_blank"
                                        href="https://g.page/lindecisopadova?share"
                                    >
                                        <DirectionsIcon />
                                    </IconButton>
                                </div>
                            </MarkerPopup>
                        </Map>
                    </MapCard>
                    <Container maxWidth="sm">
                        <Box marginTop={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5">Posizione</Typography>
                                    <Typography>Indeciso Padova</Typography>
                                    <Typography>Via gattamelata, 21</Typography>
                                    <Typography>35128 Padova PD</Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5">Orari</Typography>
                                    <Timetable>
                                        <Typography>lun - gio</Typography>
                                        <div>
                                            <Typography>12:00-15:00</Typography>
                                            <Typography>18:30-22:00</Typography>
                                        </div>
                                        <Typography>ven</Typography>
                                        <div>
                                            <Typography>12:00-15:00</Typography>
                                            <Typography>18:30-23:00</Typography>
                                        </div>
                                        <Typography>sab</Typography>
                                        <div>
                                            <Typography>18:30-23:00</Typography>
                                        </div>
                                        <Typography>dom</Typography>
                                        <div>
                                            <Typography>chiuso</Typography>
                                        </div>
                                    </Timetable>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Container>
            </MapContainer>
        </DefaultSpacing>
    );
};
