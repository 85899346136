import styled from 'styled-components';

export const LegalPaper = styled.div`
    h1,
    h2,
    h3 {
        font-weight: 700;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 64px;
        margin-bottom: 16px;
    }

    br {
        display: none;
    }

    p,
    li {
        font-family: 'Work Sans';
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 2rem;
    }

    a {
        color: ${({ theme }) => theme.palette.primary.main};
    }
`;
