import React from 'react';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { firestore } from 'config/firebase';
import { Typography, Button, Container, Toolbar, Box } from '@material-ui/core';
import { link } from 'fs';
import styled from 'styled-components';

const AnnouncementBar = styled.div`
    background-color: ${({ theme }) => theme.palette.primary.main};

    .MuiToolbar-root {
        ${({ theme }) => theme.breakpoints.down('sm')} {
            flex-direction: column;
            padding: ${({ theme }) => theme.spacing(1)}px 0;
        }
    }

    .MuiTypography-root {
        color: ${({ theme }) => theme.palette.background.default};
        opacity: 1;
        font-size: 1rem;
        flex-shrink: 1;
        margin-right: ${({ theme }) => theme.spacing(1)}px;
        line-height: 120%;

        ${({ theme }) => theme.breakpoints.down('sm')} {
            margin-right: 0;
            margin-bottom: ${({ theme }) => theme.spacing(1)}px;
        }
    }

    .MuiBox-root {
        display: flex;
        flex-direction: row-reverse;
        flex-shrink: 0;
        align-items: stretch;

        ${({ theme }) => theme.breakpoints.down('sm')} {
            width: 100%;
        }
    }

    .MuiButton {
        &-root {
            color: ${({ theme }) => theme.palette.background.default};
            margin-right: ${({ theme }) => theme.spacing(1)}px;
            box-shadow: none;
            border: 2px solid ${({ theme }) => theme.palette.background.default};
            height: 40px;
            flex-grow: 1;
        }

        &-containedPrimary {
            margin-right: 0;
            background-color: ${({ theme }) => theme.palette.background.default};
            color: ${({ theme }) => theme.palette.primary.main};
            flex-shrink: 0;
        }
    }
`;

const Announcement = () => {
    const [announcements, isLoading] = useCollectionDataOnce<{
        message: string;
        links: {
            label: string;
            target: string;
            isExternal: boolean;
        }[];
        date: firebase.firestore.Timestamp;
        shouldBroadcast: boolean;
    }>(
        firestore
            .collection('announcements')
            .orderBy('date', 'desc')
            .limit(1),
    );

    if (!announcements || !announcements[0] || !announcements[0].shouldBroadcast) return null;
    const { message, links, date } = announcements[0];

    return (
        <AnnouncementBar>
            <Container>
                <Toolbar disableGutters>
                    <Typography>{message}</Typography>
                    <Box display="flex" marginLeft="auto">
                        {links.map((link, index) => (
                            <Button
                                variant={index === 0 ? 'contained' : 'outlined'}
                                color="primary"
                                target="_blank"
                                component="a"
                                {...{ [link.isExternal ? 'href' : 'to']: link.target }}
                            >
                                {link.label}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AnnouncementBar>
    );
};

export default Announcement;
