import { Container, Grid, Hidden, Typography } from '@material-ui/core';
import { analytics } from 'config/firebase';
import React from 'react';
import { BigButton } from 'styles/buttons';
import { DefaultSpacing } from 'styles/Layout';
import { RatioBox, RatioBoxContent } from 'styles/ratioBox';
import { AboutImage, AboutWrapper } from './styles';

export const About = () => {
    return (
        <AboutWrapper>
            <DefaultSpacing>
                <Container>
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6} lg={5}>
                            <RatioBox w={1} h={1}>
                                <RatioBoxContent>
                                    <AboutImage src="https://firebasestorage.googleapis.com/v0/b/indeciso-c2cdb.appspot.com/o/Place%2FSala2.jpg?alt=media&token=b7fc7e13-e2ef-430d-ab2e-7ba632dd4f79" />
                                </RatioBoxContent>
                            </RatioBox>
                        </Grid>
                        <Hidden mdDown>
                            <Grid item lg={1} />
                        </Hidden>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2">Chi siamo</Typography>
                            <Typography>
                                Aperitivo con spuncetti preparati al momento, taglieri di affettati
                                e formaggi ricercati, ampia selezione di vini naturali birre
                                artigianali. Cena con piatti sia tradizionali che rivisitati,
                                particolare attenzione alle stagionalità, antipasti sfiziosi
                                preparati con cura, primi piatti con pasta artigianale, carne alla
                                griglia di cavallo, cinghiale e manzo. Il menù è in continuo
                                aggiornamento per dare al cliente sempre qualcosa di nuovo seguendo
                                attentamente le stagionalità. Dolci artigianali fatti in casa.
                            </Typography>
                            <BigButton
                                alterned
                                onClick={() => {
                                    analytics.logEvent('about-learn-click', {
                                        origin: 'website-about',
                                    });
                                }}
                            >
                                Scopri di più
                            </BigButton>
                        </Grid>
                    </Grid>
                </Container>
            </DefaultSpacing>
        </AboutWrapper>
    );
};
