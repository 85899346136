import { Button, Container, Hidden, Typography } from '@material-ui/core';
import { analytics } from 'config/firebase';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from 'styles/Link';
import { Divider, Links, LogoWrapper, Spacer, TopnavBar, TopnavToolbar } from 'styles/Topnav';
import Announcement from 'components/AnnouncementBanner';

const Topnav = () => {
    return (
        <>
            <TopnavBar>
                <Announcement />
                <Container>
                    <TopnavToolbar disableGutters>
                        <LogoWrapper to="/">
                            <Typography variant="h6">L'INDECISO</Typography>
                        </LogoWrapper>
                        <Spacer />
                        <Links>
                            <Button
                                variant="outlined"
                                color="default"
                                component={RouterLink}
                                to="/menu"
                                onClick={() => {
                                    analytics.logEvent('open-menu', {
                                        origin: 'website-topnav',
                                    });
                                }}
                            >
                                Menu
                            </Button>
                            <Hidden smDown>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    href="tel:+393453436293"
                                    onClick={() => {
                                        analytics.logEvent('preorder-call', {
                                            origin: 'website-topnav',
                                        });
                                    }}
                                >
                                    Prenota • 3453436293
                                </Button>
                            </Hidden>
                            <Hidden mdUp>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    href="tel:+393453436293"
                                    onClick={() => {
                                        analytics.logEvent('preorder-call', {
                                            origin: 'website-topnav',
                                        });
                                    }}
                                >
                                    Prenota
                                </Button>
                            </Hidden>
                        </Links>
                    </TopnavToolbar>
                </Container>
            </TopnavBar>
        </>
    );
};

export default Topnav;
