import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

type Props = {
    to?: string;
    href?: string;
    className?: string;
} & React.HTMLProps<HTMLAnchorElement>;

const ConditionalLink: React.FC<Props> = ({
    to,
    href,
    className,
    download,
    target,
    role,
    children,
}: Props) =>
    to ? (
        <Link smooth to={to} className={className}>
            {children}
        </Link>
    ) : (
        <a href={href} className={className} download={download} target={target} role={role}>
            {children}
        </a>
    );

export default ConditionalLink;
