import styled from 'styled-components';

export const Wrapper = styled.div`
    min-height: 100vh;
    background-color: ${props => props.theme.palette.background.default};
    padding-top: 64px;
`;

export const DefaultSpacing = styled.div`
    padding: 128px 0;

    ${({ theme }) => theme.breakpoints.down('xs')} {
        padding: 64px 0;
    }
`;
