import React from 'react';
import { About } from './sections/About';
import { Cover } from './sections/Cover';
import { Position } from './sections/Position';

export const Home = () => {
    return (
        <>
            <Cover />
            <About />
            <Position />
        </>
    );
};
