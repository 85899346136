import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/performance';

import * as firebase from 'firebase/app';

const config = {
    apiKey: 'AIzaSyB0mfTA-_o3pirt4-3FpHceeowkXpxeks8',
    authDomain: 'indeciso-c2cdb.firebaseapp.com',
    databaseURL: 'https://indeciso-c2cdb.firebaseio.com',
    projectId: 'indeciso-c2cdb',
    storageBucket: 'indeciso-c2cdb.appspot.com',
    messagingSenderId: '242086421764',
    appId: '1:242086421764:web:957f2212eb4126b48d5c51',
    measurementId: 'G-TTS1Z0EB7Z',
};

firebase.initializeApp(config);

firebase.performance();

export const app = firebase.app();
export const analytics = firebase.analytics();
export const firestore = firebase.firestore();
