import React, { useState, useEffect } from 'react';
import { PageWrapper } from 'components/PageWrapper';
import { Container, CircularProgress, Box } from '@material-ui/core';
import axios from 'axios';
import { LegalPaper } from './styles';

const Cookies = () => {
    const [cookiesContent, setCookiesContent] = useState('');
    const [networkError, setNetworkError] = useState<Error | null>(null);

    useEffect(() => {
        const getPolicy = async () => {
            const policyResponse = await axios
                .get(
                    'https://www.iubenda.com/api/privacy-policy/73740718/cookie-policy/no-markup',
                    {
                        responseType: 'text',
                    },
                )
                .catch(setNetworkError);
            console.log('fuck');
            if (networkError || !policyResponse) return;
            setCookiesContent(policyResponse.data.content);
        };

        getPolicy();
    }, []);

    return (
        <PageWrapper>
            {cookiesContent ? (
                <Container>
                    <i>Se non paghi il prodotto, il prodotto sei tu.</i>
                    <LegalPaper dangerouslySetInnerHTML={{ __html: cookiesContent }} />
                </Container>
            ) : (
                <Box height={256} display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
        </PageWrapper>
    );
};

export default Cookies;
