import { AppBar, Dialog, Toolbar } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'styles/Link';

export const TopnavBar = styled(AppBar).attrs({
    elevation: 0,
    color: 'secondary',
    position: 'fixed',
})`
    background: ${props => props.theme.palette.background.default};
    color: ${props => props.theme.palette.text.primary};
    box-shadow: 0 48px 64px 64px #131313;

    ${props => props.theme.breakpoints.down('md')} {
        box-shadow: 0 0px 16px 16px #131313, 0 48px 64px 64px #131313;
    }

    ${props => props.theme.breakpoints.down('xs')} {
        box-shadow: 0 0px 8px 4px #131313, 0 16px 32px 0px #131313;
    }

    ${Link} {
        margin: 0 16px;
    }
`;

export const TopnavToolbar = styled(Toolbar)``;

export const LogoWrapper = styled(RouterLink)`
    height: 24px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    transition: background 0.2s;

    &:hover {
        background: rgba(0, 0, 0, 0.1);
        margin: -8px;
        padding: 8px;
        box-sizing: content-box;
        border-radius: 12px;
    }

    svg,
    img {
        fill: ${props => (props.theme.palette.type === 'dark' ? '#fff' : '#333')};
        height: 100%;
        margin-right: 12px;
    }
`;

export const Divider = styled.div`
    margin: 0 12px;

    &::before {
        content: '•';
    }
`;

export const Links = styled.div`
    display: flex;
    align-items: center;

    button,
    a {
        margin-left: 24px;
    }

    /* @media (max-width: 620px) {
        display: none;
    } */
`;

export const Spacer = styled.div`
    flex-grow: 1;
`;

export const ToolbarDialog = styled(Dialog)`
    p {
        font-weight: 500;
    }

    svg {
        display: block;
    }
`;
