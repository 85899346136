import { css } from 'styled-components';

export const solidPseudo = css`
    content: '';
    display: block;
`;

export const absoluteBlock = css`
    ${solidPseudo};
    position: absolute;
`;

export const fullBlock = css`
    ${absoluteBlock};
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`;
