import { CssBaseline, MuiThemeProvider, withWidth } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import { themeContext } from 'helpers/themeContext';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';

import * as serviceWorker from './config/serviceWorker';
import getTheme from './config/theme';
import Routes from './routes';

const App = ({ width }: { width: 'xs' | 'sm' | 'md' | 'lg' | 'xl' }) => {
    const [themeState, setThemeState] = useState<'LIGHT' | 'DARK'>('DARK');

    return (
        <themeContext.Provider
            value={{
                theme: themeState,
                setTheme: () => setThemeState(themeState === 'LIGHT' ? 'DARK' : 'DARK'),
            }}
        >
            <StylesProvider injectFirst>
                <themeContext.Consumer>
                    {({ theme }) => (
                        <MuiThemeProvider theme={getTheme(width, theme)}>
                            <ThemeProvider theme={getTheme(width, theme)}>
                                <CssBaseline />
                                <div style={{ overflowX: 'hidden' }}>
                                    <Routes />
                                </div>
                            </ThemeProvider>
                        </MuiThemeProvider>
                    )}
                </themeContext.Consumer>
            </StylesProvider>
        </themeContext.Provider>
    );
};

const EnhancedApp = withWidth()(App);

const root = document.getElementById('root');

if (root !== null) ReactDOM.render(<EnhancedApp />, root);

serviceWorker.register();
