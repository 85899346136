import { Typography } from '@material-ui/core';
import { transparentize } from 'polished';
import { Popup } from 'react-mapbox-gl';
import styled, { keyframes } from 'styled-components';

export const MapContainer = styled.div``;

export const MapCard = styled.div`
    height: 384px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 48px;
`;

export const Timetable = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

const pulse = (props: { theme: any }) => keyframes`
    from {
        box-shadow: 0 0 0 0px ${transparentize(0.8, props.theme.palette.primary.main)},
        0 0 0 40px ${transparentize(0.8, props.theme.palette.primary.main)},
        0 0 0 80px ${transparentize(0.8, props.theme.palette.primary.main)};
    }
    
    to {
        box-shadow: 0 0 0 40px ${transparentize(0.8, props.theme.palette.primary.main)},
        0 0 0 80px ${transparentize(0.8, props.theme.palette.primary.main)},
        0 0 0 120px ${transparentize(1, props.theme.palette.primary.main)};
    }
`;

export const MarkerBullet = styled.div`
    height: 24px;
    width: 24px;
    background-color: ${props => props.theme.palette.primary.main};
    border-radius: 50%;
    box-shadow: 0 0 0 8px ${props => transparentize(0.8, props.theme.palette.primary.main)},
        0 0 0 24px ${props => transparentize(0.8, props.theme.palette.primary.main)},
        0 0 0 56px ${props => transparentize(0.8, props.theme.palette.primary.main)};

    animation: ${pulse} 2s infinite linear;
`;

export const MarkerPopup = styled(Popup)`
    text-align: center;

    h3 {
        margin: 0;
    }

    & .mapboxgl-popup-tip {
        visibility: hidden;
    }

    & .mapboxgl-popup-content {
        background-color: ${props => props.theme.palette.background.default};
        padding: 12px 20px;
        border-radius: 8px;
    }
`;
