import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Wrapper = styled(Paper)`
    position: fixed;
    z-index: 100;
    bottom: 16px;
    right: 16px;
    max-width: 640px;
    width: calc(100% - 32px);
    padding: 24px 32px;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.palette.background.default};
    box-shadow: 0 4px 40px -16px rgba(256, 256, 256, 0.2);

    p,
    a {
        font-size: 1rem;
        line-height: 2rem;
        margin-bottom: 8px;
    }
`;

export const Actions = styled.div`
    margin-top: 20px;

    a,
    button {
        margin-right: 16px;
        margin-bottom: 16px;
    }
`;
