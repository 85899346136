import { Container, Grid, Icon, Typography } from '@material-ui/core';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as TripadvisorIcon } from 'assets/icons/tripadvisor.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import { analytics } from 'config/firebase';
import React from 'react';
import { Link } from 'styles/Link';
import { BigButton, DecoIconButton } from 'styles/buttons';

import { CardToAction, Foot, Wrapper } from './styles';

export const Footer = () => {
    return (
        <Wrapper>
            <Container>
                <CardToAction>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={8} md={6}>
                            <Typography variant="h3">Non ti sei deciso?</Typography>
                            <Typography variant="h2">Prenota subito il tuo tavolo al +393453436293</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={6}>
                            <BigButton
                                href="tel:+393453436293"
                                onClick={() => {
                                    analytics.logEvent('preorder-call', {
                                        origin: 'website-footer',
                                    });
                                }}
                            >
                                Chiamaci
                            </BigButton>
                        </Grid>
                    </Grid>
                </CardToAction>
                <Foot>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Typography variant="h6">L'INDECISO</Typography>
                            <Typography>© {new Date().getFullYear()}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} />
                        <Grid item xs={12} sm={6} md={4} lg>
                            <Typography>
                                <strong>Informazioni</strong>
                            </Typography>
                            <Link to="/privacy">
                                <Typography>Privacy Policy</Typography>
                            </Link>
                            <Link to="/cookies">
                                <Typography>Cookie Policy</Typography>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg>
                            <Typography>
                                <strong>Seguici</strong>
                            </Typography>
                            <div>
                                <a
                                    href="https://kutt.it/teledeciso"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <DecoIconButton
                                        onClick={() => {
                                            analytics.logEvent('social-seek', {
                                                social: 'telegram',
                                                origin: 'website-footer',
                                            });
                                        }}
                                    >
                                        <Icon>
                                            <TelegramIcon />
                                        </Icon>
                                    </DecoIconButton>
                                </a>
                                <a
                                    href="https://kutt.it/indecisogram"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <DecoIconButton
                                        onClick={() => {
                                            analytics.logEvent('social-seek', {
                                                social: 'instagram',
                                                origin: 'website-footer',
                                            });
                                        }}
                                    >
                                        <Icon>
                                            <InstagramIcon />
                                        </Icon>
                                    </DecoIconButton>
                                </a>
                                <a
                                    href="https://kutt.it/indecisbook"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <DecoIconButton
                                        onClick={() => {
                                            analytics.logEvent('social-seek', {
                                                social: 'facebook',
                                                origin: 'website-footer',
                                            });
                                        }}
                                    >
                                        <Icon>
                                            <FacebookIcon />
                                        </Icon>
                                    </DecoIconButton>
                                </a>
                                <a
                                    href="https://kutt.it/tripindecisor"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <DecoIconButton
                                        onClick={() => {
                                            analytics.logEvent('social-seek', {
                                                social: 'tripadvisor',
                                                origin: 'website-footer',
                                            });
                                        }}
                                    >
                                        <Icon>
                                            <TripadvisorIcon />
                                        </Icon>
                                    </DecoIconButton>
                                </a>
                            </div>
                        </Grid>
                    </Grid>
                </Foot>
            </Container>
        </Wrapper>
    );
};
