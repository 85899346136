import {
    Box,
    CircularProgress,
    Container,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { firestore } from 'config/firebase';
import groupBy from 'helpers/groupBy';
import React, { useEffect, useState } from 'react';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { useInView } from 'react-hook-inview';
import { Sticky, StickyContainer } from 'react-sticky';
import { Transition } from 'react-transition-group';

import {
    CategoryGroup,
    MenuContent,
    MenuGroup,
    MenuItem,
    MenuSpine,
    MenuSpineBackground,
    MenuSpineLink,
    MenuSpineNav,
    MenuWrapper,
} from './styles';

type Dish = {
    category: string;
    description: string;
    name: string;
    price: number;
    tags: string[];
};

function getCategory(id: string) {
    switch (id) {
        case 'appetizer':
            return 'antipasti';
        case 'first':
            return 'primi';
        case 'second':
            return 'secondi';
        case 'cantina':
            return 'cantina';
        default:
            return '';
    }
}

const CategoryBlock = ({
    name,
    dishes,
    onFocus,
    active,
}: {
    name: string;
    dishes: Dish[];
    active: boolean;
    onFocus: (value: string) => void;
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const [ref, inView] = useInView({
        threshold: isMobile ? 0.2 : 0.6,
    });

    useEffect(() => {
        if (inView) onFocus(name);
    }, [inView]);

    return (
        <CategoryGroup id={name} ref={ref} active={active}>
            <Typography variant="h2">{getCategory(name)}</Typography>
            <MenuGroup>
                {dishes.map(dish => (
                    <MenuItem key={dish.name}>
                        <Typography variant="h3">{dish.name}</Typography>
                        {dish.description && <Typography>{dish.description}</Typography>}
                    </MenuItem>
                ))}
            </MenuGroup>
        </CategoryGroup>
    );
};

export const Menu = () => {
    const photos = {
        appetizer:
            'https://images.pexels.com/photos/277253/pexels-photo-277253.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        first:
            'https://images.pexels.com/photos/2092906/pexels-photo-2092906.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
        second:
            'https://images.pexels.com/photos/618775/pexels-photo-618775.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        cantina:
            'https://images.pexels.com/photos/774455/pexels-photo-774455.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    };

    const [dishesSnapshot, fetching] = useCollectionOnce(firestore.collection('dishes'));
    const [animate, setAnimate] = useState(false);
    const [focusedMenuCategory, setFocusedMenuCategory] = useState('appetizer');
    const [focusedMenuPhoto, setFocusedMenuPhoto] = useState(photos.appetizer);
    const menuCat = focusedMenuCategory as 'appetizer' | 'first' | 'second' | 'cantina';
    const dishes =
        dishesSnapshot && !dishesSnapshot.empty
            ? groupBy<Dish>(dishesSnapshot.docs.map(doc => doc.data()) as Dish[], 'category', {
                  appetizer: [],
                  first: [],
                  second: [],
                  cantina: [],
              })
            : [];

    useEffect(() => {
        setAnimate(true);

        setTimeout(() => {
            setAnimate(false);
            setFocusedMenuPhoto(photos[menuCat]);
        }, 800);
    }, [focusedMenuCategory]);

    if (fetching)
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="calc(100vh - 80px)"
            >
                <CircularProgress color="primary" />
            </Box>
        );

    return (
        <StickyContainer>
            <MenuWrapper>
                <Container>
                    <Grid container>
                        <Grid item xs={12} sm={4} md={5}>
                            <Sticky topOffset={80}>
                                {({ style, isSticky }) => (
                                    <MenuSpine style={style}>
                                        <Typography variant="h1">Menu</Typography>
                                        <Transition in={animate} timeout={500}>
                                            {state => {
                                                return (
                                                    <MenuSpineBackground
                                                        bg={focusedMenuPhoto}
                                                        state={state}
                                                        sticked={isSticky}
                                                    />
                                                );
                                            }}
                                        </Transition>
                                        <MenuSpineNav>
                                            {Object.keys(dishes).map(name => (
                                                <MenuSpineLink
                                                    key={name}
                                                    to={`/menu#${name}`}
                                                    active={focusedMenuCategory === name}
                                                >
                                                    {getCategory(name)}
                                                </MenuSpineLink>
                                            ))}
                                        </MenuSpineNav>
                                    </MenuSpine>
                                )}
                            </Sticky>
                        </Grid>
                        <Grid item xs={12} sm={1} md={1} />
                        <Grid item xs={12} sm={6} md={6}>
                            <MenuContent>
                                {Object.entries(dishes).map(([name, categoryDishes]) => (
                                    <CategoryBlock
                                        key={name}
                                        name={name}
                                        dishes={categoryDishes}
                                        active={focusedMenuCategory === name}
                                        onFocus={setFocusedMenuCategory}
                                    />
                                ))}
                            </MenuContent>
                        </Grid>
                    </Grid>
                </Container>
            </MenuWrapper>
        </StickyContainer>
    );
};
