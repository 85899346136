import React, { useState, useEffect } from 'react';
import { Wrapper, Actions } from './styles';
import { Typography, Button, Slide } from '@material-ui/core';
import { Link } from 'styles/Link';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';

const CookieBanner = () => {
    const [shouldShow, setShouldShow] = useState(false);

    const [acceptedCookies] = useLocalStorage<boolean>('accepted_cookies', false);

    useEffect(() => {
        setTimeout(() => {
            setShouldShow(true);
        }, 1000);
    }, []);

    return (
        <Slide in={shouldShow && !acceptedCookies} direction="left" timeout={500}>
            <Wrapper>
                <Typography>
                    Non che cambi qualcosa, ma siamo legalmente obbligati ad informarti che usiamo i
                    cookies per consentire ai nostri host di tracciarti su altri siti, app e servizi che usi (solo se senza AdBlock).
                    Se vuoi sapere altro,{' '}
                    <Link to="/cookies">guarda qua</Link>.
                </Typography>
                <Actions>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => writeStorage('accepted_cookies', true)}
                    >
                        Accetta
                    </Button>
                    <Button
                        color="primary"
                        size="large"
                        component="a"
                        href="https://www.mcdonalds.it/"
                    >
                        Vattene da qui
                    </Button>
                </Actions>
            </Wrapper>
        </Slide>
    );
};

export default CookieBanner;
