import { transparentize } from 'polished';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled, { css } from 'styled-components';
import { Link } from 'styles/Link';

export const MenuWrapper = styled.div`
    margin-bottom: 256px;

    ${({ theme }) => theme.breakpoints.down('xs')} {
        padding-top: 80px;
    }

    p {
        font-size: 1rem;
        line-height: 1.75rem;
    }
`;

export const MenuSpine = styled.div`
    position: relative;
    height: calc(100vh - 160px);
    padding-top: 160px;
    z-index: 100;

    ${({ theme }) => theme.breakpoints.down('xs')} {
        padding-top: 80px;
        height: auto;
        background-color: ${({ theme }) => theme.palette.background.default};
        box-shadow: 0 0 24px 60px ${({ theme }) => theme.palette.background.default};
    }

    h1 {
        position: absolute;
        top: 36px;
        right: 16px;
        z-index: 1;
        font-size: 3rem;
        font-weight: 400;
        line-height: 5.5rem;
        color: ${props => props.theme.palette.text.default};

        ${({ theme }) => theme.breakpoints.up('sm')} {
            writing-mode: vertical-rl;
            left: 0;
            right: auto;
            top: 240px;
            font-size: 8rem;
        }
    }
`;

export const MenuSpineNav = styled.nav`
    position: absolute;
    left: 0;
    bottom: 0;
    text-orientation: mixed;
    transform: translateY(100%);

    ${({ theme }) => theme.breakpoints.up('sm')} {
        left: auto;
        right: 0;
        bottom: auto;
        top: 160px;
        writing-mode: vertical-rl;
        padding-top: 64px;
        transform: none;
    }
`;

export const MenuSpineLink = styled(Link)<{ active: boolean }>`
    margin-left: 20px;
    height: 48px;
    text-transform: capitalize;

    &::before {
        width: 100%;
        height: 0px;
        transform: translatey(-26px);
    }

    ${({ active }) =>
        active
            ? css`
                  color: white;

                  &::before,
                  &:hover::before {
                      height: 4px;
                      width: 100%;
                  }
              `
            : null};

    ${({ theme }) => theme.breakpoints.up('sm')} {
        margin-left: 0px;
        margin-bottom: 32px;

        &::before {
            height: 100%;
            width: 0px;
            transform: translateX(-18px);
        }

        ${({ active }) =>
            active
                ? css`
                      color: white;

                      &::before,
                      &:hover::before {
                          width: 6px;
                          height: 100%;
                      }
                  `
                : null};
    }
`;

export const MenuSpineBackground = styled.div<{
    bg: string;
    state: TransitionStatus;
    sticked: boolean;
}>`
    position: absolute;
    z-index: 0;
    width: calc(90% - 48px);
    height: 100%;
    right: 48px;
    background-image: url(${props => props.bg});
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease-in, max-height 0.3s linear;

    ${({ theme }) => theme.breakpoints.down('xs')} {
        height: 500px;
        width: 100%;
        position: static;
        max-height: ${({ sticked }) => (sticked ? 48 : 160)}px;
        transition: transform 0.4s ease-in, max-height 0.3s linear;

        ${({ state }) => {
            switch (state) {
                case 'entering':
                case 'entered':
                    return 'transform: translateX(120%)';
                case 'exiting':
                case 'exited':
                    return 'transform: translateX(0%)';
                default:
                    return '';
            }
        }};
    }

    ${({ state }) => {
        switch (state) {
            case 'entering':
            case 'entered':
                return 'transform: translateY(100%)';
            case 'exiting':
            case 'exited':
                return 'transform: translateY(0%)';
            default:
                return '';
        }
    }};
`;

export const MenuContent = styled.div`
    overflow: auto;

    padding-top: 64px;
    padding-bottom: 128px;
`;

export const CategoryGroup = styled.div<{ active: boolean }>`
    padding-top: 192px;
    transition: opacity 0.3s ease-in;
    ${({ active }) => (active ? '' : 'opacity: .1')};
    ${({ theme }) => theme.breakpoints.down('xs')} {
        padding-top: 128px;
    }

    h2 {
        text-transform: capitalize;
    }
`;

export const MenuGroup = styled.div`
    margin-top: 48px;
`;

export const MenuItem = styled.div`
    padding: 40px 0;
    margin-top: 0px;
    border-bottom: 2px solid ${props => transparentize(0.6, props.theme.palette.primary.main)};

    &:first-child {
        border-top: 8px solid ${props => transparentize(0.2, props.theme.palette.primary.main)};
    }

    h3 {
        line-height: 3rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;
