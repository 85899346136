import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { transparentize } from 'polished';

declare module '@material-ui/core/styles/createTypography' {}

const capitalize = (): 'capitalize' => 'capitalize';
const dark = (): 'dark' => 'dark';

const baseTheme = {
    palette: {
        primary: {
            main: '#CCA751',
        },
        secondary: {
            main: '#0A1F35',
        },
        background: {
            default: '#f5f5f5',
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Poppins',
        h1: {
            fontSize: '2rem',
            lineHeight: '2.875rem',
            fontWeight: 600,
            marginBottom: '2rem',
        },
        h2: {
            fontSize: '1.8rem',
            lineHeight: '2.2rem',
            fontWeight: 700,
            marginBottom: '1.5rem',
        },
        h3: {
            fontSize: '1.6rem',
            lineHeight: '2rem',
            fontWeight: 700,
            marginBottom: '1rem',
        },
        h5: {
            fontSize: '1.2rem',
            fontWeight: 700,
            marginBottom: '1rem',
        },
        h6: {
            fontSize: '1.625rem',
            fontWeight: 700,
        },
        body1: {
            fontFamily: 'Work Sans',
            fontWeight: 500,
        },
        button: {
            textTransform: capitalize(),
            fontSize: '.85rem',
            fontWeight: 700,
        },
    },
    shape: {
        borderRadius: 2,
    },
    overrides: {
        MuiButton: {
            root: {
                height: 40,
            },
            outlined: {
                padding: '0 16px',
                height: 32,
                boxShadow: `0 0 0 1px ${transparentize(
                    0.6,
                    '#fff',
                )}, 0 0 0 3px #131313, 0 0 0 4px ${transparentize(0.6, '#fff')}`,
                borderWidth: 0,
                color: transparentize(0.6, '#fff'),
                '&:hover': {
                    color: transparentize(0.3, '#fff'),
                    boxShadow: `0 0 0 2px ${transparentize(
                        0.3,
                        '#fff',
                    )}, 0 0 0 5px #131313, 0 0 0 6px ${transparentize(0.3, '#fff')}`,
                },
            },
            colorPrimary: {
                color: '#fff',
            },
            containedPrimary: {
                '&:hover': {
                    backgroundColor: '#CCA751',
                    boxShadow: `0 0 0 3px #131313, 0 0 0 4px #CCA751`,
                },
            },
            sizeLarge: {
                height: 48,
            },
        },
    },
};

const baseThemeDark = {
    ...baseTheme,
    palette: {
        ...baseTheme.palette,
        type: dark(),
        background: {
            ...baseTheme.palette.background,
            default: '#131313',
        },
    },
    typography: {
        ...baseTheme.typography,
        h1: {
            ...baseTheme.typography.h1,
            // fontSize: '3.5rem',
            // lineHeight: '4.5rem',
            // marginBottom: '3rem',
        },
        h2: {
            ...baseTheme.typography.h2,
            // fontSize: '3rem',
            // lineHeight: '4.25rem',
            // marginBottom: '1.5rem',
        },
        body1: {
            ...baseTheme.typography.body1,
            // fontSize: '1.25rem',
            // lineHeight: '2.25rem',
            opacity: 0.6,
        },
    },
};

const xs = {
    ...baseTheme,
};
const sm = {
    ...xs,
};
const md = {
    ...sm,
    typography: {
        ...sm.typography,
        h1: {
            ...sm.typography.h1,
            fontSize: '3rem',
            lineHeight: '4rem',
            marginBottom: '2.5rem',
        },
    },
};
const lg = {
    ...md,
    typography: {
        ...md.typography,
        h1: {
            ...md.typography.h1,
            fontSize: '3.75rem',
            lineHeight: '4.5rem',
            marginBottom: '3rem',
        },
    },
};
const xl = {
    ...lg,
};

const xsDark = {
    ...baseThemeDark,
};
const smDark = {
    ...sm,
    ...xsDark,
};
const mdDark = {
    ...smDark,
    typography: {
        ...sm.typography,
        h1: {
            ...sm.typography.h1,
            fontSize: '3rem',
            lineHeight: '4rem',
            marginBottom: '2.5rem',
        },
        body1: {
            ...sm.typography.body1,
            fontSize: '1.5rem',
            lineHeight: '2rem',
        },
    },
};
const lgDark = {
    ...mdDark,
    typography: {
        ...md.typography,
        h1: {
            ...md.typography.h1,
            fontSize: '3.5rem',
            lineHeight: '4.5rem',
            marginBottom: '3rem',
        },
        h2: {
            ...md.typography.h2,
            fontSize: '3rem',
            lineHeight: '4.25rem',
            marginBottom: '1.5rem',
        },
        body1: {
            ...md.typography.body1,
            fontSize: '1.25rem',
            lineHeight: '2.25rem',
            opacity: 0.6,
        },
    },
};
const xlDark = {
    ...xl,
    ...lgDark,
};

const themes = {
    LIGHT: {
        xs,
        sm,
        md,
        lg,
        xl,
    },
    DARK: {
        xs: xsDark,
        sm: smDark,
        md: mdDark,
        lg: lgDark,
        xl: xlDark,
    },
};

function getTheme(breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl', theme: 'DARK' | 'LIGHT') {
    const selectTheme = (bp: 'xs' | 'sm' | 'md' | 'lg' | 'xl', t: 'DARK' | 'LIGHT') =>
        themes[t][bp];

    return createMuiTheme(selectTheme(breakpoint, theme));
}

export default getTheme;
