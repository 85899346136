import { Footer } from 'components/Footer';
import Topnav from 'components/Topnav';
import CookieBanner from 'components/CookieBanner';
import ScrollToTop from 'components/ScrollToTop';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Home } from './Home';
import { Menu } from './Menu';
import { Privacy } from './Privacy';
import Cookies from './Cookies';
import Announcement from 'components/AnnouncementBanner';

const Routes: React.FC = () => {
    return (
        <Router>
            <ScrollToTop />
            <Topnav />
            <CookieBanner />
            <Switch>
                <Route path="/">
                    <Switch>
                        <Route path="/menu" component={Menu} />
                        <Route path="/privacy" component={Privacy} />
                        <Route path="/cookies" component={Cookies} />
                        <Route path="/" component={Home} />
                    </Switch>
                    <Footer />
                </Route>
            </Switch>
        </Router>
    );
};

export default Routes;
